import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermHealthCare = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Health Care | Venues, Coverage, and Policies | LTCPG"
      ogDescription="Long term health care is complex. Corey Rieck of The Long Term Care Planning Group educate you on the care venues, coverage, and policies."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            What is Long Term Health Care?
          </h1>
          <p>Long term care is a special kind of help a person may need because of a prolonged illness or long term disability. A person could potentially collect benefits from an insurance carrier for long term health care in two ways.</p>

          <h2>Long Term Health Care Due To Physical Impairment</h2>
          <p>One way to receive long term health care benefits is due to physical impairment that limits a person’s activities of daily living, commonly known as ADLs. There are six of them: eating bathing, dressing, toileting, transferring and continence. If a person needs assistance with two out of the six activities of daily living, their medical professional writes what's called a “90 Day Certification” letter and essentially identifies there are two (or more) activities a person is struggling with or cannot do by themself. And the person is not expected to get better for at least the next 90 days.</p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_225477126.jpg"
            alt="Long Term Health Care"
          />
          <h2>Long Term Health Care Due To Cognitive Impairment</h2>
          <p>The other way a person can potentially collect benefits from an insurance carrier for long term health care is via cognitive impairment. And there are four subcomponents of orientation as to a person’s ability to recognize place or time and to have deductive or abstract reasoning and judgment. All of which relates to safety awareness or memory matters for that person. Many times, a determination can be made by looking at the person's medical records or giving the person a standardized test. It's important for you to understand the role that health insurance may play in this circumstance versus long term health care health insurance whether it's your private health insurance or Medicare to help a person get better by receiving skilled and rehabilitation care.</p>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_278525935.jpg"
          alt="Long Term Health Care - Skilled Care"
        className="right-point"
        />
      }
      imageGrow
      textPadded
      text={
        <div>
          <h2>Skilled Care</h2>
          <p>Skilled care is delivered when there's an expectation of improvement and is delivered by a medical doctor, a physician assistant, a physical therapist, a registered nurse or a highly trained and licensed medical professional. Care will include IVs and tube feeding – essentially all care delivered when there's an expectation of improvement.</p>

          <h2>Long Term Care Is Non-Skilled Care</h2>
          <p>Long term care is used when a person is in a chronic state and they are not expected to get better. They receive non-skilled care to help make them be more comfortable and help them get through each day.</p>

          <h2>Skill Care and Long Term Care Are Bookends</h2>
          <p>Think of your health insurance and long term health care is bookends. Your health insurance is going to help you get better. You receive skilled and rehabilitative care because there’s an expectation of improvement. Long term care is delivered by non-skilled caregivers when you're in a chronic state and your condition is not going to improve. These are two very different types of care and fulfill different types of needs.</p>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_113309329.jpg"
          alt="Long Term Health Care Venues"
          className="left-point"
        />
      }
      imageRight
      imageGrow
      textPadded
      text={
        <div>
          <h2>The Venues of Long Term Care</h2>
          <p>Many people have questions about where a person can receive long term health care with <a href="/long-term-care-planning/">long term health care insurance</a> to provide a continuum of care, housing and services someone may need as a result of having lived a long life. With long term health care insurance, a person can have more than a nursing home option. The person can actually choose from four venues to get care depending upon how much help they need.</p>

          <p>We’ve found in <a href="/long-term-care-insurance-specialist/">our 20+ years of experience</a>, most people want to stay in their community they created. They want to stay at home and receive home care. Perhaps they might use the services of adult daycare. If they need more assistance, they may need to move into assisted living. And usually the last stop in the continuum of care, housing and services is that of the nursing home. When they need more assistance.</p>
          <ScheduleVisitButton />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Our experience is your edge. Experienced Guidance. Trusted Solutions.</h2>

          <p>Our organization and teams work with long term <a href="/long-term-care-insurance-companies/">health care insurance companies</a>. There are two main directions that a long term health care solution can be generated from—the traditional long term health care insurance companies or the Asset Based long term health care insurance companies.</p>

          <p>A client may have an existing long term health care plan and we are happy to review it with no obligation so the client is clear on their long term health care coverage metrics and gaps. Traditional long term health care insurance companies who are denoted as “Policy Holder Service Only” are not open to new clients. We will work with the other long term health care insurance companies or help you review an existing policy with your current carrier.</p>

          <p>Traditional:</p>
          <ul>
            <li>Allianz (Policy Holder Service Only)</li>
            <li>CNA (Policy Holder Service Only)</li>
            <li>Genworth (Policy Holder Service Only)</li>
            <li>Great American (Policy Holder Service Only)</li>
            <li>John Hancock (Policy Holder Service Only)</li>
            <li>Life Secure</li>
            <li>Lincoln Financial (Policy Holder Service Only)</li>
            <li>Mass Mutual</li>
            <li>Medamerica (Policy Holder Service Only)</li>
            <li>MetLife (Policy Holder Service Only)</li>
            <li>Mutual of Omaha</li>
            <li>National Guardian Life (NGL)</li>
            <li>Physicians Mutual (Policy Holder Service Only)</li>
            <li>Prudential (Policy Holder Service Only)</li>
            <li>Thrivent</li>
            <li>Transamerica</li>
            <li>True Freedom (non-insurance pre-funded home care contract)</li>
            <li>UNUM (Policy Holder Service Only)</li>                        
          </ul>

          <p>Asset Based</p>

          <ul>
            <li>Lincoln Financial</li>
            <li>Nationwide</li>
            <li>One America (State Life)</li>
            <li>Pacific Life (Pac Life)</li>
            <li>Securian (Minnesota Life)</li>
          </ul>
          <ScheduleVisitButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/corey-rieck-long-term-planning-group.jpg"
            alt="Long Term Health Care Insurance Providers"
          />
          <Blockquote>
            <p>We custom build each client’s plan so that it is consistent with their needs, financial plan and funding strategy. There is a great deal of flexibility when contemplating a long term health care plan, but if the client CANNOT successfully navigate underwriting, this point is moot.</p>

            <p>A funding strategy may also be of interest; there is a way to “pay off” a long term health care plan as you do not have to choose the “life pay” alternative. (Pay until you pass on or pay until you meet the claim long term health care claim requirements)</p>
          </Blockquote>
        </div>
      }
    />
    <PageWrapper maxWidth color="green">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Payment Strategies</h2>
            <ul>
              <li>Single Premium Payment</li>
              <li>Payment of the premium over:
                <ul>
                  <li>5 years</li>
                  <li>10 years</li>
                  <li>20 years</li>
                </ul>
              </li>
              <li>Until age 95</li>
              <li>Guaranteed premium payment strategy</li>
            </ul>

            <p>For example, <a href="https://www.thrivent.com/about-us/" target="_blank">Thrivent</a> for me has been an excellent partner for hard to place clients with complicated long term health care medical history. They are an excellent carrier with sterling financial ratings that excel in the “hard to place long term health care case space” and I have gotten very good outcomes with clients that have challenging medical history.</p>

            <p>For example, <a href="https://www.oneamerica.com/about-us/vision-values" target="_blank">OASL</a> (One America/State Life) for me has been an excellent partner because of the leverage that they provide for multiple underwriting solutions for couples..(one is healthy, one is not—I believe that they figure that the risk is leveled out) and the fact that there is a “trap door” with the suite of annuity products if clients health doesn’t meet the Asset Care requirements. Their multiple funding strategies are valuable as well:</p>
            <ScheduleVisitButton />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_387170870.jpg"
              alt="Long Term Health Care - Guaranteed Premium Payment"
            />
            <ul>
              <li>5 Pay</li>
              <li>10 Pay</li>
              <li>20 Pay</li>
              <li>Pay to age 95</li>
              <li>Guaranteed premium payment</li>
            </ul>

            <p>For example, <a href="https://www.mutualofomaha.com/our-story/why-mutual-of-omaha" target="_blank">Mutual of Omaha</a> is valuable on the Custom Solution product because of the Cash Benefit:
            The cash benefit pays 40% of the policy’s home health benefit, up to an initial maximum of $2,400 each month.
            There’s no elimination period to satisfy before receiving the cash payment.</p>
          </div>
        }
      />
    </PageWrapper>
    <PageWrapper maxWidth color="blue">
      <TextBlock
        textPadded
        textLeft={
      <div>
        <h3>Inflation Protection Buy Up Option</h3>

        <p>Allows you to increase your inflation protection percentage once a year w/o having to prove insurability.</p>

        <h3>Security Benefit</h3>

        <p>Provides an additional 60% of your monthly reimbursement benefit that can be used to pay for care or living expenses for your uninsured partner while you receive long term health care services.</p>

        <p>Give us a call to ask your individual questions. We’ll confidentially discuss your long term health care planning needs or those of a loved one, <a href="tel:6788145088">(678) 814-5088</a>. This is a complimentary, no-obligation conversation.</p>
        <ScheduleVisitButton />
      </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_242400048.jpg"
              alt="Long Term Health Care - Security Benefit"
            />
          </div>
        }
      />
    </PageWrapper>
    <QuotePanel text="Hello World" person="John Doe" />
  </PageLayout>
  )
}

export default LongTermHealthCare